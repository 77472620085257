























import { computed, defineComponent, provide, reactive, set, watch } from '@vue/composition-api'
import ShowBasicPoll from './ShowBasicPoll.vue'
import ShowBasicAskOpinion from './ShowBasicAskOpinion.vue'
import ShowSpeak from './ShowSpeak.vue'
import ShowPoll from './ShowPoll.vue'
import ShowRank from './ShowRank.vue'
import ShowAskOpinion from './ShowAskOpinion.vue'
import ShowAskExperience from './ShowAskExperience.vue'
import ShowVideo from './ShowVideo.vue'
import ShowImage from './ShowImage.vue'
import ShowBranch from './ShowBranch.vue'
import ShowUpload from './ShowUpload.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
export default defineComponent({
    components: {
        ShowBasicPoll,
        ShowBasicAskOpinion,
        ShowSpeak,
        ShowPoll,
        ShowAskOpinion,
        ShowRank,
        ShowAskExperience,
        ShowVideo,
        ShowImage,
        ShowBranch,
        ShowUpload
    },
    props: {
        serial: Number,
        formType: {
            type: String,
            default: ProjectFormType.Basic
        },
        itemInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup(props) {
        watch(
            () => props.itemInfo,
            () => {
                Object.keys(props.itemInfo).forEach((key) => {
                    set(itemInfo, key, props.itemInfo[key])
                })
            },
            {
                deep: true
            }
        )

        const itemInfo = reactive(props.itemInfo)
        provide('itemInfo', itemInfo)
        provide('formType', props.formType)
        const typeInfo = reactive({
            type: '',
            change: (val: EntityType) => {
                typeInfo.type = val
            }
        })
        const handleData = () => {
            const { message_entity } = props.itemInfo
            if (!message_entity) {
                return
            }
            if (props.formType == ProjectFormType.Basic) {
                const pollList = [EntityType.onboarding_poll, EntityType.onboarding_poll_multi, EntityType.onboarding_poll_picture, EntityType.onboarding_poll_picture_multi]
                if (pollList.includes(message_entity.entity_type)) {
                    typeInfo.type = EntityType.onboarding_poll
                } else {
                    typeInfo.type = message_entity.entity_type
                }
            } else if (props.formType == ProjectFormType.Session) {
                const pollList = [EntityType.poll, EntityType.poll_multi, EntityType.picture_poll, EntityType.picture_poll_multi]
                const uploadList = [EntityType.upload_image, EntityType.upload_video, EntityType.upload_mix]
                if (pollList.includes(message_entity.entity_type)) {
                    typeInfo.type = EntityType.poll
                } else if (uploadList.includes(message_entity.entity_type)) {
                    typeInfo.type = EntityType.upload_image
                } else {
                    typeInfo.type = message_entity.entity_type
                }
            }
        }
        handleData()

        return {
            typeInfo,
            EntityType,
            ProjectFormType
        }
    }
})
